import { Component, OnInit } from '@angular/core';
import { VimeoApiService } from 'app/services/vimeo-api.service';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit {

    constructor() { }

    ngOnInit() {
        
    }

}
